import BackgroundColorWidget from './components/Widgets/BackgroundColorWidget';
import { ContentTypeCondition } from '@plone/volto/helpers/Slots';
import { blocksFormGenerator } from '@plone/volto/helpers/Blocks/Blocks';
import { MasonryBlockSchema } from './components/Blocks/Masonry/schema';
import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';
import type { ConfigType } from '@plone/registry';
import contactSVG from '@plone/volto/icons/user.svg';
import BlockWidthWidget from './components/Widgets/BlockWidthWidget';
import {
  getCurrentStyleByName,
  ThirdLevelAndBeyondRouteCondition,
} from './helpers';

import { defineMessages } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import MasonryBlockView from './components/Blocks/Masonry/View';
import MasonryBlockEdit from './components/Blocks/Masonry/Edit';
import Grid2Mix from './components/Blocks/Masonry/Grid2Mix';
import Grid2Standard from './components/Blocks/Masonry/Grid2Standard';
import Grid1Standard from './components/Blocks/Masonry/Grid1Standard';

import grid1StandardSVG from './components/Blocks/Masonry/presets/grid-1-standard.svg';
// import grid2DoubleSVG from './components/Blocks/Masonry/presets/grid-2-double.svg';
import grid2MixLeftSVG from './components/Blocks/Masonry/presets/grid-2-mix-left.svg';
import grid2MixRightSVG from './components/Blocks/Masonry/presets/grid-2-mix-right.svg';
import grid2StandardSVG from './components/Blocks/Masonry/presets/grid-2-standard.svg';

import '@plone/components/dist/basic.css';
import '@plone/components/dist/quanta.css';
import Slot from './components/Slots/LandingPageHeading';

import ContactBlockView from './components/Blocks/Contact/View';
import ContactBlockEdit from './components/Blocks/Contact/Edit';
import { ContactSchema } from './components/Blocks/Contact/schema';
import Breadcrumbs from '@plone/volto/components/theme/Breadcrumbs/Breadcrumbs';

type Color =
  | {
      name: string;
      label: string;
      style: Record<`--${string}`, string>;
    }
  | {
      name: string;
      label: string;
      style: undefined;
    };

type StyleDefinition = {
  style: Record<string, string>;
  name: string;
  label: string;
};

export type Preset = {
  id: string;
  title: string;
  image: string;
  data: Record<string, any>;
};

// We extend the block types with the custom ones
declare module '@plone/types' {
  export interface Content {
    background_color?: Color;
  }

  export interface SettingsConfig {
    backgroundColors: Color[];
    blockWidths: Array<StyleDefinition>;
  }

  export interface BlockConfigBase {
    themes?: Color[];
    allowedBlocks?: string[];
    presets?: Array<Preset>;
    containerToolbar?: React.ComponentType<any>;
    presetComponent?: React.ComponentType<any>;
    editBlockWrapper?: React.ComponentType<any>;
  }

  export interface BlocksConfigData {
    contact: BlockConfigBase;
    landingPageTitle: BlockConfigBase;
    separator: BlockConfigBase;
    heading: BlockConfigBase;
    masonry: BlockConfigBase;
  }
  export interface BlocksFormData {
    variation?: string;
    headline?: string;
    placeholder?: string;
  }

  export interface WidgetsConfigByWidget {
    BackgroundColorWidget: React.ComponentType<any>;
    blockWidth: React.ComponentType<any>;
  }

  // export interface BlockExtension {
  //   maxLength: number;
  // }
}

defineMessages({
  Imprint: {
    id: 'Imprint',
    defaultMessage: 'Imprint',
  },
  PrivacyPolicy: {
    id: 'Privacy Policy',
    defaultMessage: 'Privacy Policy',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'phone',
  },
  fax: {
    id: 'fax',
    defaultMessage: 'fax',
  },
  Name: {
    id: 'Your Name',
    defaultMessage: 'Your Name',
  },
  Email: {
    id: 'Your Email',
    defaultMessage: 'Your Email',
  },
  Message: {
    id: 'Your Message',
    defaultMessage: 'Your Message',
  },
  Address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  Send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  Information: {
    id: 'We look forward to getting to know you and your project.',
    defaultMessage: 'We look forward to getting to know you and your project.',
  },
  MessageSent: {
    id: 'Your message has been sent, we will get in touch with you shortly.',
    defaultMessage:
      'Your message has been sent, we will get in touch with you shortly.',
  },
  Success: {
    id: 'Message sent',
    defaultMessage: 'Message sent',
  },
  Contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  Wrong: {
    id: 'Something went wrong during form submission.',
    defaultMessage: 'Something went wrong during form submission.',
  },
});

const applyConfig = (config: ConfigType) => {
  config.blocks.requiredBlocks = [];
  config.blocks.themes = [
    {
      style: {
        '--theme-color': '#fff',
        '--background-color': '#fff',
        '--theme-high-contrast-color': '#fff',
        '--theme-foreground-color': '#000',
      },
      name: 'default',
      label: 'default',
    },
    {
      style: {
        '--background-color': '#1f2937',
        '--theme-color': '#1f2937',
        '--font-color': '#fff',
        '--theme-foreground-color': '#fff',
      },
      name: 'kblue',
      label: 'kitconcept Blue',
    },
    {
      style: {
        '--background-color': '#3B98CB',
        '--theme-color': '#3B98CB',
        '--font-color': '#fff',
        '--theme-foreground-color': '#fff',
      },
      name: 'dlrblue',
      label: 'DLR Blue',
    },
    {
      style: {
        '--background-color': '#DDEEFF',
        '--theme-color': '#DDEEFF',
        '--font-color': '#000',
        '--theme-foreground-color': '#000',
      },
      name: 'fzjblue',
      label: 'FZJ Blue',
    },
    {
      style: {
        '--background-color': '#023D6B',
        '--theme-color': '#023D6B',
        '--font-color': '#fff',
        '--theme-foreground-color': '#fff',
      },
      name: 'fzjintrablue',
      label: 'FZJ Intranet Blue',
    },
    // {
    //   style: {
    //     '--background-color': '#FFF042',
    //     '--font-color': '#1D053D',
    //   },
    //   name: 'zeelandiayellow',
    //   label: 'Zeelandia Yellow',
    // },
    {
      style: {
        '--background-color': '#0D529D',
        '--theme-color': '#0D529D',
        '--font-color': '#fff',
        '--theme-foreground-color': '#fff',
      },
      name: 'DPGblue',
      label: 'DPG Blue',
    },
  ];

  config.settings.blockWidths = [
    {
      style: {
        '--block-width': 'var(--narrow-container-width)',
      },
      name: 'narrow',
      label: 'Narrow',
    },
    {
      style: {
        '--block-width': 'var(--default-container-width)',
      },
      name: 'default',
      label: 'Default',
    },
    {
      style: {
        '--block-width': 'var(--layout-container-width)',
      },
      name: 'layout',
      label: 'Layout',
    },
    {
      style: {
        '--block-width': '100%',
      },
      name: 'full',
      label: 'Full Width',
    },
  ];

  // Blocks width convenience classes injection
  config.settings.styleClassNameExtenders.push(
    ({ data, classNames }: { data: any; classNames: Array<string> }) => {
      const currentBlockWidth =
        getCurrentStyleByName(
          config.settings.blockWidths,
          'blockWidth:noprefix',
          data,
        ) || 'default';
      if (currentBlockWidth) {
        return [...classNames, `has--block-width--${currentBlockWidth}`];
      }
      return classNames;
    },
  );

  config.settings.defaultBackgroundColor = 'kblue';

  config.settings.enableFatMenu = false;

  config.widgets.widget.BackgroundColorWidget = BackgroundColorWidget;
  config.widgets.widget.blockWidth = BlockWidthWidget;

  // config.blocks.blocksConfig.gridBlock.blocksConfig!.teaser!.schemaEnhancer =
  //   composeSchema(gridTeaserDisableStylingSchema, teaserSchemaEnhancer);

  config.blocks.blocksConfig.teaser = {
    ...config.blocks.blocksConfig.teaser,
    themes: config.blocks.themes,
  };

  config.registerSlotComponent({
    slot: 'aboveContent',
    name: 'LandingPageHeading',
    component: Slot,
    predicates: [
      ContentTypeCondition(['LRF', 'Success Story', 'Document', 'BlogFolder']),
    ],
  });

  config.registerSlotComponent({
    slot: 'aboveContent',
    name: 'Breadcrumbs',
    component: Breadcrumbs,
    predicates: [
      ThirdLevelAndBeyondRouteCondition(),
      ContentTypeCondition(['Document', 'Post']),
    ],
  });

  config.blocks.blocksConfig.contact = {
    ...config.blocks.blocksConfig.contact,
    id: 'contact',
    title: 'Contact',
    icon: contactSVG,
    group: 'common',
    view: ContactBlockView,
    edit: ContactBlockEdit,
    blockSchema: ContactSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.separator = {
    ...config.blocks.blocksConfig.separator,
    themes: config.blocks.themes,
  };

  config.blocks.blocksConfig.gridBlock = {
    ...config.blocks.blocksConfig.gridBlock,
    themes: config.blocks.themes,
    allowedBlocks: ['contact', 'image', 'listing', 'slate', 'teaser'],
  };

  config.blocks.blocksConfig.heading = {
    ...config.blocks.blocksConfig.heading,
    sidebarTab: 1,
    themes: config.blocks.themes,
    schemaEnhancer: defaultStylingSchema,
  };

  config.blocks.blocksConfig.masonry = {
    ...config.blocks.blocksConfig.gridBlock,
    id: 'masonry',
    title: 'Masonry',
    view: MasonryBlockView,
    edit: MasonryBlockEdit,
    blockSchema: MasonryBlockSchema,
    presets: [
      {
        id: 'grid1-standard',
        title: 'Grid 1 Standard',
        image: grid1StandardSVG,
        data: {
          ...blocksFormGenerator(1, 'image'),
          variation: 'grid1-standard',
        },
      },
      {
        id: 'grid2-standard',
        title: 'Grid 2 Standard',
        image: grid2StandardSVG,
        data: {
          ...blocksFormGenerator(2, 'image'),
          variation: 'grid2-standard',
        },
      },
      {
        id: 'grid2-mix-left',
        title: 'Grid 2 Mix Left',
        image: grid2MixRightSVG,
        data: {
          ...blocksFormGenerator(3, 'image'),
          variation: 'grid2-mix-left',
        },
      },
      {
        id: 'grid2-mix-right',
        title: 'Grid 2 Mix Right',
        image: grid2MixLeftSVG,
        data: {
          ...blocksFormGenerator(3, 'image'),
          variation: 'grid2-mix-right',
        },
      },
    ],
    variations: [
      {
        id: 'grid1-standard',
        title: 'Grid 1 Standard',
        render: Grid1Standard,
        maxLength: 1,
      },
      {
        id: 'grid2-standard',
        title: 'Grid 2 Standard',
        render: Grid2Standard,
        maxLength: 2,
      },
      {
        id: 'grid2-mix-left',
        title: 'Grid 2 Mix Left',
        render: Grid2Mix,
        maxLength: 3,
      },
      {
        id: 'grid2-mix-right',
        title: 'Grid 2 Mix Right',
        render: Grid2Mix,
        maxLength: 3,
      },
    ],
  };

  const gridBlocksConfig = cloneDeep(config.blocks.blocksConfig);
  config.blocks.blocksConfig.gridBlock!.blocksConfig!.contact =
    config.blocks.blocksConfig.contact;
  config.blocks.blocksConfig.masonry.blocksConfig = gridBlocksConfig;

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['de', 'en'],
    defaultLanguage: 'en',
    DSGVOBanner: {
      ...(config.settings.DSGVOBanner || []),
      tracker: {},
      modules: ['youtube', 'twitter', 'vimeo'],
      showBanner: false,
    },
  };
  return config;
};

export default applyConfig;
